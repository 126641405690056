
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/ebay/ebay-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Custom Design & Swag Collection for eBay Seattle by Swagger",
	"meta": [
		{
			"name": "description",
			"content": "Discover how Swagger designed a custom logo and unique swag collection for eBay's Seattle office. Explore Space Needle-inspired beanies, mugs, and more, fostering team spirit and local pride. Learn how Swagger can elevate your employee swag strategy."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/ebay-custom-capsule-collection"
		},
		{
			"property": "og:title",
			"content": "Custom Design & Swag Collection for eBay Seattle by Swagger"
		},
		{
			"property": "og:description",
			"content": "Discover how Swagger designed a custom logo and unique swag collection for eBay's Seattle office. Explore Space Needle-inspired beanies, mugs, and more, fostering team spirit and local pride. Learn how Swagger can elevate your employee swag strategy."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/ebay-custom-capsule-collection"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.ebay
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/logo-ebay.png')
				h1 Custom Capsule Collection
				p To celebrate their Seattle office, eBay partnered with Swagger on a capsule collection featuring a sleek Space Needle-inspired logo on beanies, mugs, water bottles, and more—fueling local pride and team spirit.
			.image
				img(src='../../images/case-studies/ebay/ebay-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p eBay is a global e-commerce leader, connecting millions across 190+ markets. Founded in 1995, it’s a marketplace built on creating economic opportunities and fostering a diverse, inclusive community.
					.imagecopy__image
						img(src='../../images/case-studies/ebay/ebay-who.png')
		
		section.section.section--accentbg.section--raiseimg
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p eBay's Seattle office, home to 360 team members, stands as the company's second-largest engineering hub outside of Silicon Valley. To celebrate their unique location and team spirit, they sought to create a capsule collection of swag that embodies the essence of Seattle. What better symbol to incorporate than the iconic Space Needle? 
					.imagecopy__image
						img(src='../../images/case-studies/ebay/ebay1.jpg')
		

		section.section.section--narrow.section--raiseimg
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p eBay teamed up with Swagger to craft a Seattle-inspired capsule collection that nailed the city’s vibe. Featuring cozy, sustainable hoodies, eco-friendly beanies, locally made MiiR mugs, branded totes, and reusable water bottles, each piece was designed for life in the Emerald City. Swagger’s design team elevated the swag with a custom Space Needle and Mount Rainier motif, adding embroidered patches, printed logos, and thoughtful details—making this collection as iconic as Seattle itself.
					.imagecopy__image
						img(src='../../images/case-studies/ebay/ebay2.jpg')
						.iconpack
							.pack.pack--multi.pack--baseline
								.item
									.icon.icon--customize
										img(src='../../images/swagger-services/customize_it.svg')
										h4 Customize It.
								.item
									.icon.icon--curate
										img(src='../../images/swagger-services/curate_it.svg')
										h4 Curate It.

			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar
		
		section.section.section--extra
			.section__content
				.quotewrap
					.fancyquote
						.fancyquote__icon.tl
							include ../../images/icons/quotation-mark.svg
						.fancyquote__icon.br
							include ../../images/icons/quotation-mark.svg
						.fancyquote__quote
							p Working with your team was so fun and easy! I loved being able to get so creative with our logo. The items were a hit at the office!
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['ebay-custom-capsule-collection']")
					
</template>
