<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/webflow/webflow-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Webflow and Swagger Transform Swag for Sales Kickoffs & Offsites",
	"meta": [
		{
			"name": "description",
			"content": "Webflow and Swagger Transform Swag for Sales Kickoffs & Offsites"
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/webflow-team-events-swag"
		},
		{
			"property": "og:title",
			"content": "Webflow and Swagger Transform Swag for Sales Kickoffs & Offsites"
		},
		{
			"property": "og:description",
			"content": "Webflow and Swagger Transform Swag for Sales Kickoffs & Offsites"
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/webflow-team-events-swag"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.webflow
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/webflow.png')
				h1 Team Events Swag
				p We partnered with Webflow to elevate their sales kickoffs and team offsites, from Napa wine sets to cozy Whistler kits that made every moment unforgettable. 
			.image
				img(src='../../images/case-studies/webflow/webflow-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p Webflow is a leading visual development platform, enabling creators to build and scale stunning websites without code. Their innovative spirit and commitment to team culture align perfectly with Swagger’s mission to create unforgettable experiences. 
					.imagecopy__image
						img(src='../../images/case-studies/webflow/webflow-who.png')

		section.section.section--narrow.section--accentbg.section--thewhat
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p From sales kickoffs to offsites, we helped Webflow amp up team spirit with custom swag kits, locally sourced treats, and branded gear. Every item was designed to motivate, celebrate, and make each event unforgettable. 
					.imagecopy__image
						img(src='../../images/case-studies/webflow/webflow-what.jpg')
		section.section.section--narrow
			.section__content
				.moreimages
					.image
						img(src='../../images/case-studies/webflow/webflow2.png')
					.image
						img(src='../../images/case-studies/webflow/webflow1.png')
		section.section.section--accentbg.section--narrow.section--raiseimg
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p Swagger crafted custom kits for each event, featuring sustainable goodies, local treats, and exclusive event logos. Highlights included a Member’s Only jacket with matching recycled tote and bold stationery for their Sales Kickoff, plus themed kits inspired by Whistler, Disney, and NOLA—adding a personal touch to every gathering. 
					.imagecopy__image
						img(src='../../images/case-studies/webflow/webflow-how.png')
				
				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--curate
								img(src='../../images/swagger-services/curate_it.svg')
								h4 Curate It. 
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar
				
								
		section.section.section--extra
			.section__content
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['webflow-team-events-swag']")
</template>
  