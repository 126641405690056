<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/dropbox/dropbox-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Dropbox Pops Up with Swagger: Global Swag Campaign in 3 Weeks",
	"meta": [
		{
			"name": "description",
			"content": "Discover how Dropbox & Swagger launched a summer pop-up shop with zero pre-purchase inventory. Explore how this innovative campaign boosted employee engagement, reduced waste, and delivered custom swag globally in just 3 weeks. Learn how Swagger can help your brand achieve similar success with pop-up shops, custom merchandise, and more."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/dropbox-pop-up-shop"
		},
		{
			"property": "og:title",
			"content": "Dropbox Pops Up with Swagger: Global Swag Campaign in 3 Weeks"
		},
		{
			"property": "og:description",
			"content": "Discover how Dropbox & Swagger launched a summer pop-up shop with zero pre-purchase inventory. Explore how this innovative campaign boosted employee engagement, reduced waste, and delivered custom swag globally in just 3 weeks. Learn how Swagger can help your brand achieve similar success with pop-up shops, custom merchandise, and more."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/dropbox-pop-up-shop"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.dropbox
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/logo-dropbox.png')
				h1 Pop Up Shop
				p Dropbox teamed up with Swagger to level up employee gifting with a limited-edition pop-up shop. The result? Custom swag delivered globally, zero waste, and major employee love—fast.
			.image
				img(src='../../images/case-studies/dropbox/dropbox-main.png')
			

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p Dropbox is the one cloud-based platform to keep life organized and keep work moving. With storage for photos, files, and documents, it’s easy to see why they have more than 700 million registered users across 180 countries.
					.imagecopy__image
						img(src='../../images/case-studies/dropbox/dropbox-who.png')

		section.section.section--accentbg.section--narrow.section--overlap.section--thewhat
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p Dropbox added a little fun to the summer by launching a limited-time popup shop. We accepted orders from people all over the world for 10 days, closed the store, produced and shipped the items all within three quick weeks.
					.imagecopy__image
						img(src='../../images/case-studies/dropbox/dropbox1.png')

		section.section.section--narrow.section--popup
			.section__content
				.justcopy
					h2 Pop Up Shop Benefits
					ul
						li No need to pre-purchase inventory
						li Order the exact amount you need  
						li Employees get to choose their gift
						li Seamless address collection
						li Reduce waste and cost
						

		section.section.section--accentbg.section--narrow
			.section__content
				.thehow
					h2 The How
					p Swagger went all out for Dropbox, designing a custom merch collection featuring t-shirts, blankets made from recycled plastic, and water bottles—all proudly branded. We matched every detail, down to the eco-friendly tape and recycled mailers, for a cohesive, sustainable look. Our team built a tailored pop-up shop with an easy redemption page and countdown clock, ensuring a seamless launch. From in-house storage and assembly to shipping across 180+ countries, Swagger handled every step, navigating customs and delivering Dropbox’s swag on time—making global gifting effortless and impactful.
				
				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
						.item
							.icon.icon--shop
								img(src='../../images/swagger-services/shop_it.svg')
								h4 Shop It. 
						.item
							.icon.icon--store
								img(src='../../images/swagger-services/store_it.svg')
								h4 Store It.
						.item
							.icon.icon--shipglobal
								img(src='../../images/swagger-services/ship_it_icon.png')
								h4 Ship It. Globally.
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar

		
		.section--extra
			.section__content
				.quotewrap
					.fancyquote
						.fancyquote__icon.tl
							include ../../images/icons/quotation-mark.svg
						.fancyquote__icon.br
							include ../../images/icons/quotation-mark.svg
						.fancyquote__quote
							p This store was such a success - thank you for making it so easy for us!
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['dropbox-pop-up-shop']")
</template>
  