const imagesGlob = import.meta.glob('../../../images/case-studies/*', { eager: true });

export default [
	{
		title: 'Dropbox',
		subtitle: 'Pop-Up Shop',
		description: 'Eager to elevate their employee gift experience, Dropbox partnered with Swagger for a limited-edition pop-up shop. Dive into their case study to discover how we delivered impactful custom branded swag globally, eliminated waste, and increased employee satisfaction—all within a tight timeframe!',
		image: imagesGlob['../../../images/case-studies/dropbox.png'].default,
		altImage: imagesGlob['../../../images/case-studies/dropbox-alt.png'].default,
		flippingBookId: '485136956',
		slug: 'dropbox-pop-up-shop',
	},
	{
		title: 'PrizePicks',
		subtitle: 'Merch Store',
		description: 'As the leading fantasy sports operator in North America, PrizePicks was looking to enhance their fan engagement through branded merchandise. Swagger created a uniquely designed online store featuring a dedicated "Taco Tuesday" product collection, perfectly resonating with their 2 million-strong audience.',
		image: imagesGlob['../../../images/case-studies/prizepicks.png'].default,
		altImage: imagesGlob['../../../images/case-studies/prizepicks-alt.jpg'].default,
		flippingBookId: '484882740',
		slug: 'prizepicks-merch-store',
	},
	{
		title: 'Gem',
		subtitle: 'Integrated Marketing Campaign',
		description: 'Gem wanted personalized gifting with a fun twist to boost sales. Swagger created a custom HubSpot redemption page and curated the perfect treats (including "Ted Lasso Biscuits with the Boss!") – leading to sweeter connections and higher conversion rates.',
		image: imagesGlob['../../../images/case-studies/gem.png'].default,
		altImage: imagesGlob['../../../images/case-studies/gem-alt.jpg'].default,
		flippingBookId: '485298137',
		slug: 'gem-integrated-marketing-campaign',
	},
	{
		title: 'Fastly',
		subtitle: 'Customer Gifting Site',
		description: "Fastly's growing partner network demanded a scalable gifting solution. Swagger provided a custom logo, curated sustainable gift kits, and efficient management tools, fostering deeper connections while aligning with Fastly's eco-conscious values.",
		image: imagesGlob['../../../images/case-studies/fastly.png'].default,
		altImage: imagesGlob['../../../images/case-studies/fastly-alt.jpg'].default,
		flippingBookId: '484886824',
		slug: 'fastly-customer-gifting-site',
	},
	{
		title: 'eBay',
		subtitle: 'Custom Capsule Collection',
		description: 'To celebrate their Seattle office, we partnered with eBay on a custom capsule collection. Swagger created a unique Space Needle-themed logo for beanies, mugs, water bottles, and more to fuel local pride and team spirit.',
		image: imagesGlob['../../../images/case-studies/ebay.png'].default,
		altImage: imagesGlob['../../../images/case-studies/ebay-alt.jpg'].default,
		flippingBookId: '877929241',
		slug: 'ebay-custom-capsule-collection',
	},
	{
		title: 'Webflow',
		subtitle: 'Team Events Swag',
		description: "From sales kickoffs to unforgettable offsites, Swagger fueled Webflow's team spirit with custom swag experiences. Tailored kits, locally-sourced treats, and branded gear kept teams engaged, motivated, and celebrating successes.",
		image: imagesGlob['../../../images/case-studies/webflow.png'].default,
		altImage: imagesGlob['../../../images/case-studies/webflow-alt.jpg'].default,
		flippingBookId: '1015369679',
		slug: 'webflow-team-events-swag',
	},
	{
		title: 'Assent',
		subtitle: 'Re-Brand Launch',
		description: "",
		image: imagesGlob['../../../images/case-studies/assent.png'].default,
		altImage: imagesGlob['../../../images/case-studies/assent-alt.jpg'].default,
		// flippingBookId: '1015369679',
		slug: 'assent-re-brand-launch',
	},
];
