
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/sustainable-bags/osprey_arcane_tote_pack.png';

useHead({
	"title": "Our Top 9 Favorite Sustainable Bags",
	"meta": [
		{
			"name": "description",
			"content": "Looking for sustainable bags that make the best corporate branded swag gifts? From backpacks to totes to duffels to crossbody and more...check out our favorites. Here are the top eight sustainable bags we love."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/our-top-9-sustainable-bags"
		},
		{
			"property": "og:title",
			"content": "Our Top 9 Favorite Sustainable Bags"
		},
		{
			"property": "og:description",
			"content": "Looking for sustainable bags that make the best corporate branded swag gifts? From backpacks to totes to duffels to crossbody and more...check out our favorites. Here are the top eight sustainable bags we love."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/our-top-9-sustainable-bags"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Our Top 9 Favorite Sustainable Bags
	.page__content
		section.section
			.section__content
				p We've rounded up our top nine favorite sustainable bags which have been handpicked by our team of curators. These always make the best swag because they are cool, useful and sustainable. Get ready for your employees and clients to swoon over these. Read on!
				h2 1. Osprey Arcane Tote Pack
				img(src='../../images/blog/sustainable-bags/osprey_arcane_tote_pack.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Osprey has always been a leader in sustainabilty  and giving back to protect the great outdoors for everyone to enjoy. Every time they choose recycled materials, they reduce their carbon footprint by 45-50 percent. It's hard to pick just one but a crowd favorite is their sustainable tote pack which is made of 13.5 plastic bottles and is delivered in 100% recyclable packaging. It's beautifully crafted and designed for day-to-day life. Packed with all the functionality you would expect plus some unexpected perks like the tuckaway straps so it can easily convert to a backpack and a built-in self-locking device. Worn zipper? Ripped fabric? They got you. Osprey's All Mighty Guarantee means they will repair or replace any damaged or defected product for any reason, any product, any era.

				h2 2. Moment Backpack
				img(src='../../images/blog/sustainable-bags/moment_backpack.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Looks can be deceiving. From the outside, the Moment backpack shows off it's simplicity with its recycled, waterproof fabric and clean lines. What you can't see is the thoughtful design and functionality inside. Multiple interior pockets keep items super organized and a dedicated weatherproof zipped pocket with a padded sleeve protect a 16" laptop or tablet. The sleek side access to the main compartment makes grabbing gear quick and easy. Bonus features include: an external pocket that can fit an oversized water bottle and the back panel slips over a luggage handle making it travel ready.

				h2 3. Herschel Eco Nova Duffel
				img(src='../../images/blog/sustainable-bags/herschel-duffel.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p We've always been fans of Herschel! The Eco Novel™ Duffel was designed in their signature sustainable style – made of recycled polyester. Cool features include a removable shoulder strap and the signature shoe compartment. Perfect for long days and weekends away, this classic duffel is reimagined with 100% recycled fabrics and industry-leading recycled materials from the liner to the label. The hardest decision will be choosing between black and forest green.

				h2 4. Solgaard Ocean Plastics Daypack
				img(src='../../images/blog/sustainable-bags/solgaard-daypack.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Can you believe this backpack is made entirely from recycled ocean plastics? We're obsessed. The Solgaard Shore-Tex™ Daypack features comfy shoulder straps, expandable side pockets, laptop storage, stand-alone structure and a water-resistant bottom. Oh, and did we mention it's offered at a reasonable, everyday price point? It's no surprise they are part of Climate Neutral and have saved 34million+ plastic bottles from our oceans!

				h2 5. Flip & Tumble Eco Crossbody Bag
				img(src='../../images/blog/sustainable-bags/flip_and_tumble_crossbody_bag.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p The Flip & Tumble brand packs a powerful punch! Women owned. Small business. 15 years of success delivering sustainable, beautifully designed bags. With a nod to retro style, this lightweight, packable crossbody bag keeps your hands free to explore, trek and travel with ease. They roll up into the built-in pouch for easy portability and storage. Don't miss their wide range of colors and prints plus a mix of styles from backpacks to totes to crossbody.

				h2 6. Moop Stanwix Tote
				img(src='../../images/blog/sustainable-bags/moop_tote_bag.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Who we partner with matters. From day one, we have handpicked the best brands which also share our values and support our mission. Meet Moop - a female-owned, American small business that is certainly a Brand We Love. Designed and manufactured in downtown Pittsburgh, the Stanwix Eco-Tote has a quintessential slim silhouette and is made from untreated 100% cotton canvas fabric. Since 2008, Moop has been creating products of quality construction with durable materials.

				h2 7. Cotopaxi Backpack
				img(src='../../images/blog/sustainable-bags/cotopaxi_backpack.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Who wouldn't want a one-of-a-kind backpack? Yep, be prepared for a total surprise color combo as Cotopaxi transforms 100% repurposed, durable nylon fabric into every one of their unique designs. This is the perfect daypack for all of life's adventures from school to gym to travels to hikes. It's unstructured for added versatility and features zippered and mesh pockets, adjustable straps and an interior hydration sleeve and divider. Check out the backpacks we created with an embroidered logo for Team Forethought.

				h2 8. Paravel Fold-up Weekender Bag
				img(src='../../images/blog/sustainable-bags/paravel_weekender-bag.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p Think of this as your go-everywhere weekender bag. It is the perfect balance between soft and sturdy and it is also easy on the Earth. Made from 22 upcycled plastic bottles and negative nylon it features a deep interior zippered pocket and an exterior quick-access pocket. The outer pocket even converts to slide over the handle of a roller bag. The best part? It is double-layered for durability and folds down and zips completely flat for storage or travel within a small pouch.

				h2 9. Eco Bucket Sling Bag
				img(src='../../images/blog/sustainable-bags/eco-bucket-sling-bag.png')
				img.eco-icons(src='../../images/blog/sustainable-bags/eco-icons.png')
				p We love this Eco Bucket Sling for it's customizable design, distinctive bucket silhouette and eco-friendly materials. Each bag keeps four plastic bottles out of our landfills! Made of heavyweight poly material containing 100% post-consumer recycled materials (RPET). This slouchy sling bag delivers on eco-conscious design and functionality with its drawstring closure, pocket and adjustable shoulder strap.

				h2 Which sustainable bags would your team appreciate in 2022?
				p From backpacks to duffels to totes to crossbody bags, you can mix & match the styles to fit your swag needs.  We’re ready to create your company’s standout swag for 2022. Fill out this short form: <a href="/get-started">Let's Do This.</a>


</template>
  