
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/holiday-lookbook-2022.jpg';

useHead({
	"title": "Holiday Lookbook 2022",
	"meta": [
		{
			"name": "description",
			"content": "Thoughtfully curated employee and customer holiday gifts."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/holiday2022"
		},
		{
			"property": "og:title",
			"content": "Holiday Lookbook 2022"
		},
		{
			"property": "og:description",
			"content": "Thoughtfully curated employee and customer holiday gifts."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/holiday2022"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Holiday Lookbook 2022
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/holiday-lookbook-2022.jpg')
				p Swagger’s 2022 Holiday Lookbook: The Ultimate Gift Guide to Thoughtful Holiday Gifting.
				p We are proud to present our thoughtfully curated selection of gift ideas for your employees and customers. This lookbook is filled with gifts that giveback, are produced by women and minority-owned makers, are eco-friendly and sustainable and that your employees and customers will love. 
				p
					a(href="https://lookbooks.giveswagger.com/view/984060690/" target="_blank") Check out our Lookbook here!
				
				img(src='../../images/blog/holiday-lookbook-inner.jpg')

				p To get started, just fill out this short form: <a href="/get-started.html">Let's Do This.</a>
				p Feel free to contact us anytime at (888) 517-1522 or email us at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a> to chat all things swag!
</template>
  