<script setup>
import { useMenuStore } from "@static/compose"
import { useRouter } from "vue-router";

const menuStore = useMenuStore()
const router = useRouter();

router.afterEach(() => {
	menuStore.isOpen = false;
});
</script>

<template lang="pug">
//- main menu overlay
.menu-overlay#menuOverlay(:class="{ 'menu-overlay--visible': menuStore.isOpen }")
	.menu-overlay__header
		header.header.header--menu-overlay
			.header__logo
				.logo
					router-link(to="/")
						img(src="../../images/swagger-logo-white.png" alt="Swagger Logo in white")
			.header__cta
				router-link.btn.btn--primary(to="/get-started") Let's Do This
			.header__menu-trigger
				button.menuTrigger(v-menu-trigger) X
	nav.menu-overlay__nav
		ul
			li
				router-link(to="/swagger-services") Swagger Services
			li
				router-link(to="/brands-we-love") Brands We Love
			li
				router-link(to="/our-work") Our Work
			li
				router-link(to="/lookbooks") Lookbooks
			li
				router-link(to="/our-impact") Our Impact
			li
				router-link(to="/case-studies") Case Studies
			li
				router-link(to="/get-started") Get Started
</template>

<style scoped></style>