
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/solgaard/solgaard1.png';

useHead({
	"title": "Brands We Love: Solgaard—Sustainable Travel Gear with Purpose",
	"meta": [
		{
			"name": "description",
			"content": "Learn why Swagger loves Solgaard. Stylish, eco-friendly travel gear that reduces plastic waste and supports sustainability. Perfect for impactful corporate swag."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/swagger-brands-we-love-solgaard"
		},
		{
			"property": "og:title",
			"content": "Brands We Love: Solgaard—Sustainable Travel Gear with Purpose"
		},
		{
			"property": "og:description",
			"content": "Learn why Swagger loves Solgaard. Stylish, eco-friendly travel gear that reduces plastic waste and supports sustainability. Perfect for impactful corporate swag."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/swagger-brands-we-love-solgaard"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brands We Love: Solgaard
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/solgaard/solgaard1.png' alt="Traveler using eco-friendly Solgaard Carry On Closet suitcase for a sustainable and organized journey")
				p As March winds down and we wrap up B Corp Month, we’re keeping the celebration going. B Corp Month doesn’t stop here—and neither does Solgaard. This B Corp certified brand is redefining sustainable travel, offering stylish and high-quality gear that’s making a positive impact on the planet. Solgaard isn’t just about creating sleek luggage—they’re actively cleaning up our oceans and reducing plastic waste with every product sold.
				p For every item you purchase, Solgaard removes 6 pounds of ocean-bound plastic from coastal communities. With over 2.1 million pounds of plastic diverted so far, they’re turning their commitment to sustainability into real-world change. Whether you’re reducing your own environmental footprint or looking for branded swag that makes a statement, Solgaard is a brand that’s as purposeful as it is practical.

				h2 Meet the Founder: Adrian Solgaard
				img(src='../../images/blog/solgaard/solgaard2.png')
				p Solgaard was founded in 2016 by Adrian Solgaard, whose love for travel and passion for the environment led him to create a brand that blends sustainability with innovation. After seeing the environmental damage caused by traditional travel gear, Adrian sought to design products that not only served travelers’ needs but also contributed to cleaning up the planet. Today, Solgaard’s B Corp certification is a testament to Adrian’s vision of a cleaner, more sustainable world.

				h2 The Impact: Sustainability in Action
				img(src='../../images/blog/solgaard/solgaard3.png')
				p Solgaard is deeply committed to tackling the growing problem of plastic waste. Beyond designing eco-friendly products, they collaborate with organizations like Sungai Watch and NextWave Plastics to create lasting, scalable solutions.
				p Through these partnerships, Solgaard has funded new plastic waste collection facilities and supported eco-brick infrastructure, resulting in over 2.1 million pounds of plastic diverted from the oceans. These efforts are just the beginning, and Solgaard’s ongoing projects continue to address the environmental crisis, one product at a time.

				h2 Solgaard’s Products: Designed with Purpose
				img(src='../../images/blog/solgaard/solgaard4.png')
				p Solgaard’s products are more than just travel essentials—they’re designed with purpose. Take the Voyager Weekender, Carry On Closet Suitcase, and Venture Travel Backpack. Each is crafted from eco-conscious materials like recycled ocean plastic, combining sustainability with functionality to create products that help you travel smarter and greener.
				ul
					li <b>Voyager Weekender</b>: Ideal for quick getaways with plenty of space and a sleek design.
					li <b>Carry On Closet Suitcase</b>: Features an innovative closet system that keeps clothes wrinkle-free for effortless packing and unpacking.
					li <b>Venture Travel Backpack</b>: A versatile bag that offers smart compartments and a minimalist design for daily use or travel.

				p Each product is designed to make your travels easier while reducing your environmental footprint.

				h2 Why Swagger Partners with Solgaard
				img(src='../../images/blog/solgaard/solgaard5.png')
				p At Swagger, we believe your swag should reflect your values. That’s why we’re proud to partner with Solgaard. Their eco-friendly travel gear is setting the standard for sustainability, making it easy to align your brand with a company that’s making a tangible impact. Every purchase supports Solgaard’s mission to clean up the planet—one plastic bottle at a time.
				p By choosing Solgaard for your branded swag, you’re not just investing in high-quality products—you’re supporting a brand that’s committed to social good and environmental responsibility.

				h3 Let’s Make Your Swag Matter
				p Want your swag to do more than just look good? Solgaard’s eco-friendly travel gear is the perfect way to showcase your brand’s dedication to sustainability.
				p Reach out to us at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a> or Just fill out this short form: <router-link to="/get-started">Let’s Do This!</router-link> to start creating impactful, eco-conscious branded swag that’s practical, stylish, and good for the planet.
</template>
  