<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/assent/assent-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Assent’s Rebrand Launch: Celebrating B Corp Certification with Sustainable Swag",
	"meta": [
		{
			"name": "description",
			"content": "Discover how Swagger helped Assent bring their rebrand and B Corp certification to life with Pantone-matched packaging, sustainable swag, and a memorable company-wide launch experience."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/assent-re-brand-launch"
		},
		{
			"property": "og:title",
			"content": "Assent’s Rebrand Launch: Celebrating B Corp Certification with Sustainable Swag"
		},
		{
			"property": "og:description",
			"content": "Discover how Swagger helped Assent bring their rebrand and B Corp certification to life with Pantone-matched packaging, sustainable swag, and a memorable company-wide launch experience."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/assent-re-brand-launch"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.assent
	section.hero
		.hero__bg
		.hero__content
			.copy
				img.gem(src='../../images/partner-logos/assent.png')
				h1 Re-Brand Launch
				p Assent unveiled their rebrand to celebrate becoming a B Corp, and Swagger helped bring it to life with sustainable, on-brand swag and an unforgettable launch experience.
			.image
				img(src='../../images/case-studies/assent/assent-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p Assent, the leader in supply chain sustainability management, empowers manufacturers to drive transparency, mitigate risks, and stay compliant. Their rebrand was a bold declaration of their new B Corp certification, tying their commitment to sustainability and ethical business practices directly to their refreshed identity.
					.imagecopy__image
						img(src='../../images/case-studies/assent/assent-who.png')

		section.section.section--narrow.section--accentbg.section--raiseimg
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p Assent’s rebrand and B Corp certification were all about aligning their identity with their values, and their launch swag reflected this perfectly. Swagger curated a Pantone-matched blue box filled with sustainably sourced items like Marine Layer apparel, MiiR drinkware, and pens made from recycled materials—each chosen to embody Assent’s commitment to sustainability and ethical business. A custom pamphlet detailed the story behind every item, connecting the brand’s values to the products, making the unboxing experience both meaningful and impactful. 
					.imagecopy__image
						img(src='../../images/case-studies/assent/assent-what.jpg')
		
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p Swagger managed every detail, from design and production to kitting and shipping, ensuring the boxes arrived just in time for Assent’s company-wide all-hands meeting. With a playful “Don’t open until the all-hands” message, employees unveiled their refreshed brand together, celebrating their B Corp milestone as one team. To keep the momentum going, we also launched an employee store with free shipping and special discounts to commemorate the occasion. 
					.imagecopy__image
						img(src='../../images/case-studies/assent/assent-how.png')

				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--curate
								img(src='../../images/swagger-services/curate_it.svg')
								h4 Curate It.
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
						.item
							.icon.icon--box
								img(src='../../images/swagger-services/box_it.svg')
								h4 Box It.
						.item
							.icon.icon--shop
								img(src='../../images/swagger-services/shop_it.svg')
								h4 Shop It. 
						.item
							.icon.icon--shipglobal
								img(src='../../images/swagger-services/ship_it_icon.png')
								h4 Ship It. Globally.
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar
							
		section.section.section--extra
			.section__content
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['assent-re-brand-launch']")

</template>
  