// import './handlers';

import { createPinia } from "pinia"
import { createApp } from "vue"
import { createHead } from "@unhead/vue"
import { createRouter, createWebHistory } from "vue-router"
import VueGtag from 'vue-gtag';

import App from "@static/App.vue"

import { menuTrigger } from "@static/compose"
import { routes as swaggerRoutes } from '@static/routes.js'

export const createStatic = () => {
  console.log("Give Swagger Static")

  const head = createHead()
  const pinia = createPinia()
  const routes = router()

  return createApp({
    ...App,
    name: "GiveSwaggerStatic"
  })
    .use(routes)
    .use(head)
    .use(pinia)
    .use(VueGtag, {
      includes: [
        // { id: "GTM-T3H2FVC" },
        { id: "AW-340645754" }
      ]
    })
    .directive("menuTrigger", menuTrigger)
}

function router() {
  return createRouter({
    history: createWebHistory(),
    routes: swaggerRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        console.log(to.hash);
        return {
          el: to.hash,
        }
      }

      return { top: 0 }
    }
  });
}
