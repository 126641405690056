<script setup>
import { computed } from 'vue';
import caseStudies from './caseStudies';
import CaseStudiesPreview from './CaseStudiesPreview.vue';

const props = defineProps({
	showAll: Boolean, // displays all available case studies (if true, overrides other prop options)
	showThese: Array, // display case studies by slug
	excludeThese:  Array, // don't display these by slug
	altStyle: Boolean, // true for different images + displayed copy
});

const shownCaseStudies = computed(() => {
	return props.showAll ? caseStudies : 
			props.showThese ? caseStudies.filter((x) => props.showThese.includes(x.slug)) : 
				getRandomThree(caseStudies);
});

function getRandomThree() {
	const shuffled = [...caseStudies].sort(() => 0.5 - Math.random()).filter((x) => !props.excludeThese?.includes(x.slug));
	return shuffled.slice(0, 3);
}

</script>

<template>
	<div class="preview-cards-index">
		<CaseStudiesPreview v-for="cs in shownCaseStudies" :lookbook="cs" :showTitle="showAll" :altStyle="altStyle" />
	</div>
</template>