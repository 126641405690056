<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/prizepicks/prizepicks-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "PrizePicks Scores Big with Custom Merch Store & Taco Tuesday Collection",
	"meta": [
		{
			"name": "description",
			"content": "Discover how PrizePicks, the top North American fantasy sports platform, partnered with Swagger to create a winning merch store experience. Explore their custom-designed store, on-brand Taco Tuesday collection, and seamless fulfillment for 2 million users."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/prizepicks-merch-store"
		},
		{
			"property": "og:title",
			"content": "PrizePicks Scores Big with Custom Merch Store & Taco Tuesday Collection"
		},
		{
			"property": "og:description",
			"content": "Discover how PrizePicks, the top North American fantasy sports platform, partnered with Swagger to create a winning merch store experience. Explore their custom-designed store, on-brand Taco Tuesday collection, and seamless fulfillment for 2 million users."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/prizepicks-merch-store"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.prizepicks
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/prizepicks.png')
				h1 Merch Store
				p PrizePicks, North America’s top fantasy sports operator, teamed up with Swagger to create a standout online store to drop a custom "Taco Tuesday" merch collection for 10 million fans. 
			.image
				img(src='../../images/case-studies/prizepicks/prizepicks-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p PrizePicks isn’t just playing the game—they’re dominating it. With 10M+ members and major league partnerships with the NBA, MLB, and NASCAR, they’ve earned their spot as the #1 fantasy sports platform in North America. 
					.imagecopy__image
						img(src='../../images/case-studies/prizepicks/prizepicks-who.png')

		section.section.section--narrow.section--accentbg.section--raiseimg
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p Swagger brought the heat with a custom merch store designed just for PrizePicks’ passionate fan base. From an immersive, eye-catching design to custom-branded packaging, we delivered it all. Plus, we took the heavy lifting off their plate by seamlessly integrating with our shipping platform, managing every step of fulfillment: warehousing, inventory management, white-labeled customer service, and shipping thousands of packages right to fans’ doors.
					.imagecopy__image
						img(src='../../images/case-studies/prizepicks/prizepicks-what.png')
		
		section.section.section--narrow.section--raiseimg
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p PrizePicks’ Taco Tuesday merch drop needed to wow, and Swagger delivered big. From taco-themed packaging and margarita glasses to custom “Le Taco” dog toys, every detail screamed Taco Tuesday vibes. Partnering with LA Apparel, we created bold t-shirt designs using advanced techniques like puffed ink and embroidery, complete with custom neck tags for a premium touch. 
						p Swagger handled it all—from seamless store integration to inventory management, global shipping, and quality control—ensuring every piece met PrizePicks’ high standards. The exclusive merch store wasn’t just a shopping experience—it was a celebration. Fans snatched up t-shirts, stickers, and glassware so fast that some items sold out in under 24 hours!
					.imagecopy__image 
						img(src='../../images/case-studies/prizepicks/prizepicks-how.png')
						img(src='../../images/case-studies/prizepicks/prizepicks-how2.png')

				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
						.item
							.icon.icon--connect
								img(src='../../images/swagger-services/connect_it.png')
								h4 Connect It.
						.item
							.icon.icon--shop
								img(src='../../images/swagger-services/shop_it.svg')
								h4 Shop It.
						.item
							.icon.icon--store
								img(src='../../images/swagger-services/store_it.svg')
								h4 Store It.
						.item
							.icon.icon--ship
								img(src='../../images/swagger-services/ship_it.svg')
								h4 Ship It.			
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar

		section.section.section--extra
			.section__content
				.quotewrap
					.fancyquote
						.fancyquote__icon.tl
								include ../../images/icons/quotation-mark.svg
						.fancyquote__icon.br
								include ../../images/icons/quotation-mark.svg
						.fancyquote__quote
							p We love Swagger's collaborative partnership that allowed us to create uniquely designed products and ensured our members received the highest quality product and service. Plus being Women Owned is a big bonus! 
						.fancyquote__attribution
							p - Chip 
		
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['prizepicks-merch-store']")


</template>
  