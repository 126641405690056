<script setup>
	const props = defineProps(['lookbook', 'showTitle', 'altStyle']);

	const image = props.altStyle && props.lookbook.altImage ? props.lookbook.altImage : props.lookbook.image;
</script>

<template>
	<router-link class="preview-card" :class="{'preview-card--alt': altStyle}" :to="'/case-studies/'+lookbook.slug">
		<div class="preview-card__image">
			<div class="image">
				<img v-bind:src="image">
			</div>
		</div>
		<div class="preview-card__content" v-if="showTitle || altStyle">
			<div class="title">
				<h2><span>{{lookbook.title}}</span> <span>{{ lookbook.subtitle }}</span></h2>
			</div>
		</div>
	</router-link>
</template>

<style></style>