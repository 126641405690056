<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/fastly/fastly-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Fastly Scales Sustainable Partner Gifting with GiveSwagger",
	"meta": [
		{
			"name": "description",
			"content": "Discover how Swagger helped Fastly streamline and elevate their partner gifting program. Explore custom logo design, curated sustainable gift kits, and scalable solutions for a growing network. Learn how Swagger can enhance your partner relationships."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/fastly-customer-gifting-site"
		},
		{
			"property": "og:title",
			"content": "Fastly Scales Sustainable Partner Gifting with GiveSwagger"
		},
		{
			"property": "og:description",
			"content": "Discover how Swagger helped Fastly streamline and elevate their partner gifting program. Explore custom logo design, curated sustainable gift kits, and scalable solutions for a growing network. Learn how Swagger can enhance your partner relationships."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/fastly-customer-gifting-site"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

block content
.page.page--case-study.fastly
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/fastly.png')
				h1 Customer Gifting Site
				p Fastly’s growing partner network needed scalable, eco-friendly gifting. Swagger delivered with custom-branded sustainable kits and streamlined management tools, building connections while keeping it green. 
			.image
				img(src='../../images/case-studies/fastly/fastly-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p "Built by developers. Driven by values." Fastly powers the web’s best experiences with their edge cloud platform, enabling fast, reliable, and secure digital solutions while staying true to innovation, inclusivity, and sustainability.
					.imagecopy__image
						img(src='../../images/case-studies/fastly/fastly-who.png')
		section.section.section--narrow.section--accentbg.section--thewhat
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p Fastly needed a scalable gifting solution that matched their eco-conscious values. They wanted a fresh logo for their partner program and curated sustainable gift kits featuring branded tees, sleek packaging, and a dedicated gifting site. Swagger brought it all to life with style and purpose.
					.imagecopy__image
						img(src='../../images/case-studies/fastly/fastly-what.png')
		
		section.section.section--narrow.section--thehow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p Swagger created a bold custom logo and applied it across tees, eco-friendly gift boxes, and the program’s site. We curated premium, sustainable products and streamlined the gifting process with a scalable, user-friendly platform—helping Fastly connect with partners while staying true to their values.
					.imagecopy__image
						img(src="../../images/case-studies/fastly/fastly-how.png")
				
		section.section.section--narrow.section--accentbg
			.section__content
				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--curate
								img(src='../../images/swagger-services/curate_it.svg')
								h4 Curate It.
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
						.item
							.icon.icon--box
								img(src='../../images/swagger-services/box_it.svg')
								h4 Box It. 
				
						.item
							.icon.icon--shop
								img(src='../../images/swagger-services/shop_it.svg')
								h4 Shop It. 
						.item
							.icon.icon--store
								img(src='../../images/swagger-services/store_it.svg')
								h4 Store It.
						.item
							.icon.icon--ship
								img(src='../../images/swagger-services/ship_it.svg')
								h4 Ship It.
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar
							
		section.section.section--extra
			.section__content
				.quotewrap
					.fancyquote
						.fancyquote__icon.tl
								include ../../images/icons/quotation-mark.svg
						.fancyquote__icon.br
								include ../../images/icons/quotation-mark.svg
						.fancyquote__quote
							p Swagger has been an absolute pleasure to work with. We love the creative collaboration with their amazing team.
						.fancyquote__attribution
							p - Jamie 
					
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle="true" :excludeThese="['fastly-customer-gifting-site']")

</template>
  