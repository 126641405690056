import { createEventHook, useWindowScroll, tryOnUnmounted } from "@vueuse/core"
import { watch } from "vue"
import { defineStore } from "pinia"


export const useSticky = () => {
  const { y: scrollPos } = useWindowScroll()

  const stick = createEventHook()
  const unstick = createEventHook()

  // the gap between these must be greater than the different between the header's height when sticky vs not-sticky
  const thresholdDown = 50
  const thresholdUp = 2

  const unwatch = watch(scrollPos, newVal => {
    if (newVal > thresholdDown) {
      stick.trigger()
    } else if (newVal < thresholdUp) {
      unstick.trigger()
    }
  })

  tryOnUnmounted(() => {
    unwatch()
  })

  return {
    onStick: stick.on,
    onUnstick: unstick.on
  }
}

export const useMenuStore = defineStore("menu", {
  state: () => ({
    isOpen: false
  }),
  actions: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    }
  }
})

export const menuTrigger = {
  created() {
    console.log("menuTrigger created")
  },

  mounted(el, binding) {
    console.log("menuTrigger mounted")
    el.addEventListener("click", () => useMenuStore().toggleMenu())
  },

  unmounted(el, binding) {
    el.removeEventListener("click", () => useMenuStore().toggleMenu())
  }
}
