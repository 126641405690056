<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/case-studies/gem/gem-main.png';
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	"title": "Gem Boosts Sales with Personalized Gifting & Hubspot integration",
	"meta": [
		{
			"name": "description",
			"content": ""
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/case-studies/gem-integrated-marketing-campaign"
		},
		{
			"property": "og:title",
			"content": "Gem Boosts Sales with Personalized Gifting & Hubspot integration"
		},
		{
			"property": "og:description",
			"content": ""
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/case-studies/gem-integrated-marketing-campaign"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

.page.page--case-study.gem
	section.hero
		.hero__bg
		.hero__content
			.copy
				img(src='../../images/partner-logos/gem.png')
				h1 Integrated Marketing Campaign
				p Gem teamed up with Swagger to bring personalized gifting to life—with playful touches like “Ted Lasso Biscuits with the Boss” that built stronger connections and boosted conversions.
			.image
				img(src='../../images/case-studies/gem/gem-main.png')

	.page__content
		section.section.section--narrow
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The Who
						p Gem, a $1B talent engagement platform, helps recruiting teams build stronger relationships and cultivate diverse, high-quality talent pipelines. With a focus on personalization and connection, Gem is transforming how teams engage with top talent. 
					.imagecopy__image
						img(src='../../images/case-studies/gem/gem-who.png')

		section.section.section--narrow.section--accentbg.section--raiseimg
			.section__content
				.imagecopy
					.imagecopy__copy
						h2 The What
						p Gem wanted to make gifting personal and fun while driving real results. Swagger stepped up with a Ted Lasso-inspired campaign featuring imported English shortbread, custom cookie boxes, and branded notecards with QR codes for scheduling meetings and entering to win an Apple Gift Card. The campaign blended charm and strategy, helping Gem connect with prospects in a way that was both memorable and effective. 
					.imagecopy__image
						img(src='../../images/case-studies/gem/gem-what.png')
		
		section.section.section--narrow.section--raiseimg
			.section__content
				.imagecopy.imagecopy--flip
					.imagecopy__copy
						h2 The How
						p Swagger handled every detail to ensure Gem’s campaign was as effortless as it was effective. We sourced authentic shortbread from England, created custom cookie boxes, and paired them with branded notecards featuring QR codes that tied back to Gem’s HubSpot platform. Orders were kitted, ready to ship, and dispatched within 24 hours of submission. From concept to fulfillment, Swagger’s streamlined approach turned cookies into conversions, delivering sweet results in record time.
					.imagecopy__image
						img(src='../../images/case-studies/gem/gem-how.png')
				.iconpack
					.pack.pack--multi.pack--baseline
						.item
							.icon.icon--customize
								img(src='../../images/swagger-services/customize_it.svg')
								h4 Customize It.
						.item
							.icon.icon--connect
								img(src='../../images/swagger-services/connect_it.png')
								h4 Connect It.
						.item
							.icon.icon--store
								img(src='../../images/swagger-services/store_it.svg')
								h4 Store It.
						.item
							.icon.icon--ship
								img(src='../../images/swagger-services/ship_it.svg')
								h4 Ship It.
			.colorbar
				.bar
				.bar
				.bar
				.bar
				.bar
							
		section.section.section--extra
			.section__content
				.seemore
					h3 See More Case Studies
					CaseStudiesIndex(:altStyle='true' :excludeThese='["gem-integrated-marketing-campaign"]')
</template>
  