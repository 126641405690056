<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@unhead/vue";
import Layout from "@static/Layout.vue";
import { setMarketingCookie } from "./scripts/utilities/marketing-params";

const route = useRoute();
const router = useRouter();

onMounted(async () => {
    await router.isReady();
    setMarketingCookie(route.query);
});

useHead({
    script: [
        {
            src: "https://www.google.com/recaptcha/enterprise.js?render=6LdhzP0iAAAAAGI-pXgR84c7TAfKpy92oINxSpls&onload=recaptchaOnloadCallback"
        },
        {
            children: `function recaptchaOnloadCallback() {grecaptcha?.enterprise.ready(function() {grecaptcha.enterprise.execute('6LdhzP0iAAAAAGI-pXgR84c7TAfKpy92oINxSpls', {action: 'page_load'});}); }`
        }
    ]
})
</script>

<template>
    <Layout>
        <!-- <template #header>
            <router-view name="header" />
        </template> -->

        <router-view :key="$route.path" />

        <!-- <template #footer>
            <router-view name="footer" />
        </template> -->
    </Layout>
</template>
