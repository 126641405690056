
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/miir/miir1.png';

useHead({
	"title": "Slash Plastic, Slay Style: MiiR's Bold Custom Swag by Swagger",
	"meta": [
		{
			"name": "description",
			"content": "Discover why MiiR is the top choice for company swag at Swagger: MiiR offers a range of bold and stylish bottles, tumblers, and more. Say goodbye to plastic and elevate your swag game with MiiR's eco-friendly and fashionable products. Join the swag revolution with MiiR today!"
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/miir"
		},
		{
			"property": "og:title",
			"content": "Slash Plastic, Slay Style: MiiR's Bold Custom Swag by Swagger"
		},
		{
			"property": "og:description",
			"content": "Discover why MiiR is the top choice for company swag at Swagger: MiiR offers a range of bold and stylish bottles, tumblers, and more. Say goodbye to plastic and elevate your swag game with MiiR's eco-friendly and fashionable products. Join the swag revolution with MiiR today!"
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/miir"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Miir
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/miir/miir1.png' alt="A MiiR narrow mouth water bottle in front of rock statues. Bottle can be custom branded by Swagger")
				
				p Every second, 1,500 plastic bottles are tossed into landfills and oceans, painting a grim picture of the current throwaway culture. Today, tapping into clean water and getting a filter is easier than ever, so that substantial number isn’t just unnecessary—it's crazy!
				p MiiR wants to fix that problem (and then some). MiiR isn’t another brand trying to sell you a water bottle, they’re on a mission to slash our plastic use and do it with style. When you pick up one of their sleek water bottles, thermoses, or tumblers, you're not just buying custom swag—you're joining a movement. Swagger believes in the hype about MiiR, because trust us, they're more than worth the buzz.
				p Swagger and MiiR share the same passion for sustainability, and we’re obsessed with brands who stay true on their relentless pursuit of making this world a better place. Read on to find out why MiiR is one of our top picks for custom branded merch. 
				
				h2 All About MiiR 
				img(src='../../images/blog/miir/miir2.png' alt="Coffee being poured into a white custom branded MiiR mug")
				p <a href="https://www.miir.com/pages/miir-founders-story">The founder of MiiR</a>, Bryan Papé had a lightbulb moment after a skiing crash. While mulling over his career as a social media expert and the impact (or lack thereof) he was making, Bryan was hit with a realization. In a world obsessed with convenience, leading to mountains of plastic waste, there was a desperate need for a shift. And he wanted to make a direct impact on how we do water. 
				p At the time, bottled water was all the rage, and large water companies were being wasteful. People who wanted to stay hydrated didn't always have other viable options. Reusable water bottles were already on the market, but they were strictly utilitarian.  
				p Bryan decided to <a href="https://www.miir.com/pages/about">create custom MiiR water bottles</a>. And he knew his bottles had to be both stylish and functional so the average person would ditch disposable alternatives. 
				p Fast forward, and MiiR's lineup has exploded beyond just bottles. They've got company branded swag from travel tumblers to growlers, all rocking that signature MiiR blend of cool design and eco-consciousness. 

				h2 How MiiR Gives Back 
				img(src='../../images/blog/miir/miir3.png' alt="Image of girls apart of The International Women’s Coffee Alliance (IWCA) standing between coffee plants and around coffee beans.")
				p MiiR is all about making the world a better place, giving to global nonprofits that tackle everything from clean water access to sustainability projects.
				p One nonprofit MiiR donates to that Swagger really digs? The International Women’s Coffee Alliance (IWCA). The IWCA is a nonprofit 501(c)(3) organization on a mission to connect chapters around the world, all to support and boost the growth of women in the international coffee scene.
				p They believe in the magic that happens when women are empowered—communities start to flourish and thrive. It's all about giving women in coffee the spotlight and resources they need, showing that when one woman rises, the whole community rises with her. Something big we at Swagger can get behind.
				p And, as of 2022, MiiR has given over $3.8 million, empowering over 200 organizations working across 26 countries. So, you can be confident knowing each piece of MiiR branded merch puts funds towards game-changing initiatives.
				
				h2 Why Swagger Loves MiiR
				img(src='../../images/blog/miir/miir4.png' alt="White MiiR wide mouth water bottle can be custom branded by Swagger")
				p We're totally into MiiR because they've got it all: cool designs, real-deal sustainability, and a bold way of shaking things up. Choosing MiiR for your corporate swag is about giving something that pops, is actually useful, and shouts out that you're into more than just making bucks—you're here to make a difference. 
				p At Swagger, our obsession with MiiR is fueled by their fusion of designs, authentic sustainability, and daring innovation. MiiR's products aren't merely functional—they're statements of style with a sleek, contemporary vibe that everyone will love. MiiR branded swag not only amps up your brand's visibility but also guarantees that your swag is a sought-after treasure, not just another forgettable-freebie.

				h2 Choose MiiR for Your Company Swag 
				img(src='../../images/blog/miir/miir5.png' alt="Custom branded blue narrow mouth MiiR water bottle curated by Swagger")
				p Giving your clients a MiiR bottle or tumbler is way more than a smart choice—it's sharing a little kindness. MiiR is all about incredible functionality paired with a love for our planet. Their products don't just look good; they're a friendly nod towards saying goodbye to single-use items.
				p At its heart, MiiR combines trendy designs with eco-consciousness, and a positive approach to making a difference. It's corporate branded swag that says you care about your clients and the earth alike.
				p Swagger brings all your swag needs under one roof. We create beautifully branded gifts, build ready-to-shop swag stores, and take care of kitting, warehousing, inventory, and on-demand shipping. We’re here to partner with you on stylish swag that they’ll want to show off and you’ll be proud to give.
				p Looking for more company swag inspo? Dive into Swagger’s <a href="/lookbooks/2024-trends">2024 Trends Lookbook</a>.
				
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started.html">Let's do this!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  