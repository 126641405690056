<script setup>
import { useHead } from "@unhead/vue";
import CaseStudiesIndex from '../../scripts/components/caseStudies/CaseStudiesIndex.vue';

useHead({
	title: "PAGE_TITLE",
	meta: [
		{ name: "description", content: "PAGE_DESCRIPTION" },
		{ rel: "canonical", href: "https://giveswagger.com/PAGE_SLUG" },
		{ property: "og:title", content: "PAGE_TITLE" },
		{ property: "og:description", content: "PAGE_DESCRIPTION" },
		{ property: "og:image", content: "https://giveswagger.com/assets/swagger-social.jpg" },
		{ property: "og:url", content: "https://giveswagger.com/PAGE_SLUG" }
	]
})

</script>

<template lang="pug">
	.page.page--case-study
		section.hero
			.hero__bg
			.hero__content
				.copy
					//- img(src='@static/images/partner-logos/LOGO.png')
					h1
					p
				.image
					//- img(src='@static/images/case-studies/PATH/PATH-main.jpg')

		.page__content
			section.section.section--thewho
				.section__content
					.imagecopy
						.imagecopy__copy
							h2 The Who
							p
						.imagecopy__image
							//- img(src='@static/images/case-studies/PATH/PATH-who.jpg')

			section.section.section--thewhat
				.section__content
					.imagecopy.imagecopy--flip
						.imagecopy__copy
							h2 The What
							p
						.imagecopy__image
							//- img(src='@static/images/case-studies/PATH/PATH-what.jpg')
			
			section.section.section--thehow
				.section__content
					h2 The How
					p
					.pack.pack--two
						.item
							.pack.pack--two
								.item
									.icon.icon--customize
										img(src='@static/images/swagger-services/customize_it.svg')
										h4 Customize It.
								.item
									.icon.icon--shop
										img(src='@static/images/swagger-services/shop_it.svg')
										h4 Shop It. 
						.item
							//- 
								
			section.section.section--extra
				.section__content
					.pack.pack--three
						.item
							img(src='@static/images/case-studies/ebay/ebay3.jpg')
						.item
							img(src='@static/images/case-studies/ebay/ebay4.jpg')
						.item
							img(src='@static/images/case-studies/ebay/ebay5.jpg')
			
				.section__content
					.seemore
						h3 See More Case Studies
						CaseStudiesIndex(:showAll="false" excludeSlug="PAGE_SLUG")
</template>