
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/aapi/aapi1.png';

useHead({
	"title": "Meet Swagger’s Top AAPI-Owned Brands for Inspired Custom Swag",
	"meta": [
		{
			"name": "description",
			"content": "Join Swagger in celebrating AAPI Heritage Month! Get to know our favorite AAPI-owned brands like Cadence Capsules and Copper Cow Coffee, perfect for adding a touch of culture and innovation to your branded swag."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/AAPI-faves"
		},
		{
			"property": "og:title",
			"content": "Meet Swagger’s Top AAPI-Owned Brands for Inspired Custom Swag"
		},
		{
			"property": "og:description",
			"content": "Join Swagger in celebrating AAPI Heritage Month! Get to know our favorite AAPI-owned brands like Cadence Capsules and Copper Cow Coffee, perfect for adding a touch of culture and innovation to your branded swag."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/AAPI-faves"
		},
		{
			"property": "og:image",
			"content": `https://giveswagger.com${OgImage}`
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Celebrating AAPI Heritage Month with Swagger’s Favorite AAPI-Owned Brands 
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/aapi/aapi1.png' alt="Asian American founders of Brevite and brothers Brandon, Dylan, and Elliot Kim.")
				p May is Asian American and Pacific Islander (AAPI) Heritage Month, a time to honor the contributions and influence of Asian Americans and Pacific Islander Americans to the history, culture, and achievements of the United States. The term AAPI encompasses a broad group of people from East Asia, Southeast Asia, South Asia, and the Pacific Islands, each with unique cultures, languages, and histories. During AAPI Heritage Month, we recognize the strength and impact of these communities.
				p Swagger proudly supports AAPI-owned companies that bring not only exceptional products but also rich stories and traditions that enhance our collective experience.
				p Here are some AAPI-owned brands making significant impacts in the corporate branded swag world, which we are excited to feature, along with the inspiring stories of their founders: 

				h2 1. CB Station
				img(src='../../images/blog/aapi/aapi2.png' alt="CB Station Large Class Tote can be custom branded by Swagger")
				p CB Station was founded by Hana Lee, an entrepreneur of Korean descent. Known for its high-quality, eco-friendly canvas tote bags and home goods, CB Station combines functionality with clean, minimalist design. Lee's commitment to sustainability and ethical manufacturing processes aligns perfectly with Swagger's mission, making CB Station a celebrated partner in our community for company swag. 

				h2 2. Appointed 
				img(src='../../images/blog/aapi/aapi3.png' alt="Woman holding a collection of Appointed Notebooks curated by Swagger.")
				p Appointed was brought to life by Suann Song, who harbored a passion for beautifully designed stationery. This AAPI-owned business enhances the workspace with meticulously crafted notebooks and planners that reflect Song’s dedication to quality and sustainability. Her products are not just tools but works of art, making them perfect for custom swag that conveys elegance and thoughtfulness. 

				h2 3. Fossa Apparel
				img(src='../../images/blog/aapi/aapi4.png' alt="Man and woman wearing Fossa Apparel jackets curated by Swagger.")
				p Fossa Apparel is the creation of Jenny Li, a visionary designer who focuses on stylish and functional clothing. Li's designs are known for their innovation and quality, making Fossa Apparel a favorite for customizable company branded swag. Her commitment to diversity and inclusion shines through in every aspect of her business. 

				h2 4. Copper Cow Coffee
				img(src='../../images/blog/aapi/aapi5.png' alt="A Copper Cow Pour Over Coffee in a white mad can be custom branded by Swagger.")
				p Debbie Wei Mullin founded Copper Cow Coffee to share the rich coffee culture of Vietnam with the world. Her company focuses on simple, sustainable solutions for high-quality Vietnamese coffee. Mullin’s leadership as a woman and an AAPI entrepreneur emphasizes eco-friendly practices and a farm-to-cup ethos, making her coffee kits a global branded merch favorite. 

				h2 5. Elemental
				img(src='../../images/blog/aapi/aapi6.png' alt="The Elemental Coffee Dress shirt in an assortment of colors curated by Swagger.")
				p Founded in 2016 by Vinh Lieu and Seth Inyang, Elemental is a company that specializes in creating eco-friendly drinkware. Although most known for their drinkware, what you may not know is that Elemental also creates sustainable clothing and accessories that are stylish, comfortable, and great for the planet.
				p Both founders are deeply committed to sustainability, with their innovative designs reflecting a blend of Vinh's background from Vietnam, where clean water is prized, and Seth's Midwest roots, where water is abundant. Their unique perspectives fuse into branded merchandise that not only look good but also promote a healthier planet.

				h2 6. Brevite
				img(src='../../images/blog/aapi/aapi7.png' alt="An assortment of Brevite backpacks leaning against a tree.")
				p Brothers Brandon, Dylan, and Elliot Kim launched Brevite with a vision to create backpacks that cater to the needs of modern adventurers. Their designs reflect a blend of intuitive functionality and minimalist style, deeply influenced by their Korean-American heritage. Brevite’s products are sustainably made, aligning with Swagger's commitment to environmentally conscious corporate swag every step of the way. 

				h2 7. Cadence Capsules 
				img(src='../../images/blog/aapi/aapi8.png' alt="A beehive shaped assortment of Cadence Capsules designed by Swagger.")
				p Stephanie Hon founded Cadence Capsules to tackle the challenges of traveling with personal care products. Her unique, magnetic, leak-proof containers are designed for efficiency and sustainability, reflecting her commitment to eco-friendly travel solutions. Hon’s innovative approach to product design has made Cadence Capsules a hit among eco-conscious consumers for branded swag. 

				h2 8. KINTO
				img(src='../../images/blog/aapi/aapi9.png' alt="A variety of KINTO reusable bottles lined up in a row.")
				p KINTO was originally founded in Japan and is known for its sophisticated approach to coffee and teaware that enhances daily rituals. The brand’s commitment to offering products that balance usability with aesthetics has garnered a dedicated following. Now led by Miki Koide, KINTO continues to embody its founding principles while expanding its presence in the U.S. Koide’s leadership emphasizes sustainable practices and the art of slow living, aligning perfectly with Swagger's values.
				p When we support AAPI-owned businesses, we're doing more than just buying products, we're celebrating their achievements and embracing diversity in the business world. At Swagger, we're excited to partner with these innovative brands, showcasing their unique stories and products to our clients. It's all about honoring the rich heritage of the AAPI community, especially during AAPI Heritage Month. So, let’s choose gifts that aren't just thoughtful but also celebrate and resonate with diverse cultures and histories. Join us in making a difference!


				p Swagger brings all your swag needs under one roof. We create beautifully branded gifts, build ready-to-shop swag stores, and take care of kitting, warehousing, inventory, and on-demand shipping. We’re here to partner with you on stylish swag that they’ll want to show off and you’ll be proud to give.
				p Looking for more company swag inspo? Dive into Swagger’s <a href="https://giveswagger.com/lookbooks/2024-trends">2024 Trends Lookbook</a>.
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>

</template>
  